import { StaticImage } from "gatsby-plugin-image";
import React from 'react';
import { Card } from "react-bootstrap";
import AccordionItem from "../components/accordion-item";
import CTA from '../components/cta';
import Layout from '../components/layout';
import Seo from "../components/seo";
import PortfolioFeed from "../components/portfolio-feed";
import Quote from "../components/quote";
import backgroundImage from '../images/backrounds/web-design-consulting.png';


const Index = ({location}) => {

  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Website Design Consulting Firms - Web Designer"
        description="The secret to world-class website design and development is tapping the talent of our friendly and innovative website design consultants." 
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${backgroundImage})`
          }}
        >
          <div className="hero-title bg-primary-transparent ">
            <h1 className="text-white">
              website design consulting services
            </h1>
            <p className="text-white">Since 2003, 729 Solutions has been crafting digital projects that speak directly
              to our clients– and
              their clients’– needs.</p>
            <p className="text-white">No matter your budget, brand or the scope of your business, our in-house team of
              talented developers
              and consultants will deliver a website that makes you stand out.</p>
            <CTA href="/contact-us/" text="Tell us about your project" className="btn btn-secondary-light"/>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2 className="text-center mb-1">what’s the secret to outshining your competition online?</h2>
              <p className="text-center">(Pssst: It’s world-class web design.)</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark">
        <div className="container text-white py-6">
          <div className="d-flex row ">
            <div className="col-lg-6 order-md-1 pt-5">
              <div style={{maxWidth: '100px'}}>
                <StaticImage src="../icons/web-design-services.png" alt="Web Design Services"/>
              </div>
            </div>
            <div className="col-lg-6 order-md-0 pt-5">
              <h2 className="text-white">a website consultant guides the direction of your digital project</h2>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-lg-6">
              <p>With our website design consulting services, everything is based on user experience and engagement
                research, the best practices in user navigation, and superior website design principles.</p>
              <p>Your website won’t be a bland, boring brochure. With the help of consultants from 729 Solutions, it
                will be fully customized to your business and designed with your precise customers in mind.</p>
            </div>
            <div className="col-lg-6">
              <p>We’re extremely flexible. We use well-known platforms like WordPress and Shopify, and we also create
                hard-coded custom websites to meet every client’s unique needs.</p>
            </div>
          </div>
          <div className="d-flex row">
            <div className="col-lg-6 order-md-1 pt-5">
              <div style={{maxWidth: '100px'}}>
                <StaticImage src="../icons/web-design-services-2.png" alt="Web Design Services 2"/>
              </div>
            </div>
            <div className="col-lg-6 order-md-0 pt-5">
              <h2 className="text-white">your web consultant helps you compete (and win)</h2>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-lg-6">
              <p>A web consultant is trained to see the big picture of your market position, including what your
                competitors are doing. They’ll help you redirect traffic away from other options and straight to your
                site.</p>
              <p>Your 729 Solutions consultant will give your business a boost by staying ahead of your competitors at
                every turn, using the best and most up-to-date internet design and marketing principles.</p>
            </div>
            <div className="col-lg-6">
              <p>Whenever you have a question about modifying your site or meeting a specific business challenge, your
                consultant will be by your side with expert advice that helps you take action and move forward with
                confidence.</p>
            </div>
          </div>
          <div className="d-flex row">
            <div className="col-lg-6 order-md-1 pt-5">
              <div style={{maxWidth: '100px'}}>
                <StaticImage src="../icons/web-design-services-3.png" alt="Web Design Services 3"/>
              </div>
            </div>
            <div className="col-lg-6 order-md-0 pt-5">
              <h2 className="text-white">a web consultant works hand-in-hand with your brand</h2>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-lg-6">
              <p>With our website design consulting services, everything is based on user experience and engagement
                research, the best practices in user navigation, and superior website design principles.</p>
              <p>Your website won’t be a bland, boring brochure. With the help of consultants from 729 Solutions, it
                will be fully customized to your business and designed with your precise customers in mind.</p>
            </div>
            <div className="col-lg-6">
              <p>We’re extremely flexible. We use well-known platforms like WordPress and Shopify, and we also create
                hard-coded custom websites to meet every client’s unique needs.</p>
            </div>
          </div>
          <div className="d-flex row">
            <div className="col-lg-6 order-md-1 pt-5">
              <div style={{maxWidth: '100px'}}>
                <StaticImage src="../icons/web-design-services-4.png" alt="Web Design Services 4"/>
              </div>
            </div>
            <div className="col-lg-6 order-md-0 pt-5">
              <h2 className="text-white">our portfolio is a testament to our success.</h2>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-lg-6">
              <p>From nonprofits and startups that need to build a brand identity, to well-established names in every
                field, a wide-ranging portfolio shows the depth of experience with web design and development
                projects.</p>
            </div>
            <div className="col-lg-6">
              <p>A great web designer should have a portfolio to back them up.</p>
            </div>
          </div>
          <div className="row">
            <div className="col text-center mt-6">
               <CTA text="View our portfolio" href="/portfolio/" className="btn btn-primary" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2 className="text-center">why choose us as your web design consultant?</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <Card className="p-5 custom-cards card-border text-left">
                <Card.Title><h3>you need to rev up your site speed</h3></Card.Title>
                <Card.Body className="p-0">
                  <p>Site speed is extraordinarily important in today’s digital marketplace. Most site visitors will
                    only give websites 1 or 2 seconds of loading time before they start to feel impatient. Do you want
                    to lose clients over 2 seconds of slowness?</p>

                  <p>Website design consulting services help you touch up every tiny detail of your site, so pages load
                    fast and every user has a smooth, seamless experience.</p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-lg-4">
              <Card className="p-5 custom-cards card-border text-left">
                <Card.Title><h3>we always make it mobile and responsive</h3></Card.Title>
                <Card.Body className="p-0">
                  <p>A website design and development consultant will help you brush up your web presence to meet or
                    exceed modern standards for mobile-friendliness and responsiveness</p>
                  <p>This makes it a pleasure for your target audience to interact with your brand, appreciate your
                    content, and become a loyal customer.</p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-lg-4">
              <Card className="p-5 custom-cards card-border text-left">
                <Card.Title> <h3>WE MAKE SURE YOU STAND OUT WITH YOUR BRANDING</h3> </Card.Title>
                <Card.Body className="p-0">
                  <p>A website design consultant understands what it takes to stand out online and magnetically attract
                    your precise target audience. Your brand will leave your competitors in the dust.</p>

                  <p>Let’s start a collaborative consultation about the design services you need to build website
                    traffic and engagement with fresh leads. We’re here to help you meet your goals through world-class
                    web design services.</p>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark">
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2 className="text-center text-white">what our clients say about us</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <Quote
                dark
                quote="The design team did an awesome job defining granular user personas and created the foundation for the look and feel of our brand."
                name="Eric Major"
                company="Bundle Loans"
              />
            </div>
            <div className="col-12 col-lg-4">
              <Quote
                dark
                quote="They do a good job of setting the right expectations. The fact that we are coming back time and again is a testimony!!"
                name="Stuart Harris"
                company="Alta FoodCraft"
              />
            </div>
            <div className="col-12 col-lg-4">
              <Quote
                dark
                quote="729 allowed us the flexibility to self-implement, but were with us every step of the way. They answered questions, brainstormed, provided solutions and best practices for anything we needed."
                name="Kayla Fischer"
                company="Hirevue"
              />
            </div>
          </div>
        </div>
      </section>
      <PortfolioFeed className="my-6"/>
      <section>
        <div className="container pb-6">
          <div className="row">
            <div className="col">
              <h2 className="text-center">wondering about web design consulting services? here’s a helpful faq.</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="accordion-729">
                <AccordionItem
                  id="0"
                  checked
                  title="what is a web design consultant?"
                  text={[
                    <p>A web design consultant understands all of the basic principles of good website design, plus has
                      a firm grasp of SEO strategy and digital marketing.
                      <br></br>
                      <br></br>
                      While keeping an eye on the big picture
                      of your online brand, they also manage the hundreds of design details that form the framework of
                      a successful business website.</p>
                  ]}
                />
                <AccordionItem
                  id="1"
                  title="What is web layout?"
                  text={[
                    <p>Web layout is the logic and design that forms the look, feel and usability of your
                      website
                      <br></br>
                      <br></br>
                      With the right web layout, your site will naturally encourage user behavior that
                      moves potential customers through your web experience and into the buying stage and beyond.
                    </p>
                  ]}
                />
                <AccordionItem
                  id="2"
                  title="what does a web designer do?"
                  text={[
                    <p>A web designer has a special blend of artistic creativity and technical expertise, which they use
                      to make your website more beautiful and functional.
                      <br></br>
                      <br></br>
                      Within the framework you've developed
                      with your web design consultant, the web designer creates an aesthetically-appealing site that
                      feels intuitive, professional and often even fun to use.
                    </p>
                  ]}
                />
                <AccordionItem
                  id="3"
                  title="which platform is best for web development?"
                  text={[
                    <p>The best route to world-class web design is the one that meets the needs of your site, whether
                      that's a content management system like WordPress or a custom programming solution that uses PHP
                      or Java.
                      <br></br>
                      <br></br>
                      The web designers at 729 Solutions will work in partnership with you to decide
                      which option is best for your unique requirements.
                    </p>
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark">
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <div className="text-center">
                <h2 className='text-white mb-1'>want to speak with us about your web design consulting service
                  needs?</h2>
                <p className='text-white mb-6'>We love a challenge. Bring us your toughest problems and we’ll help you
                  sort out the pieces!</p>
                <CTA href="/contact-us/" text="Tell Us About Your Web Design Project"
                     className="btn btn-secondary-light"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
};

export default Index;
